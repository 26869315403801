import React, {Component} from 'react';
import { Image, Container } from 'semantic-ui-react';
import './banner.css';

class Banner extends Component {
    render() {
        return (
            <div className="banner">
                <Container textAlign='center'>
                <Image src={require('../assets/img/banner-img.png')} centered rounded size='large' alt="Frijoles-logo"></Image>
                <h3>Prices Subject to change without notice.</h3>
                </Container>
            </div>
        )
    }
}

export default Banner;